'use strict';

// # simple-statistics
//
// A simple, literate statistics system.

var ss = module.exports = {};

// Linear Regression
ss.linearRegression = require(17);
ss.linearRegressionLine = require(18);
ss.standardDeviation = require(43);
ss.rSquared = require(32);
ss.mode = require(25);
ss.min = require(23);
ss.max = require(20);
ss.sum = require(45);
ss.quantile = require(30);
ss.quantileSorted = require(31);
ss.iqr = ss.interquartileRange = require(15);
ss.medianAbsoluteDeviation = ss.mad = require(19);
ss.chunk = require(7);
ss.shuffle = require(40);
ss.shuffleInPlace = require(41);
ss.sample = require(34);
ss.ckmeans = require(8);
ss.sortedUniqueCount = require(42);
ss.sumNthPowerDeviations = require(46);

// sample statistics
ss.sampleCovariance = require(36);
ss.sampleCorrelation = require(35);
ss.sampleVariance = require(39);
ss.sampleStandardDeviation = require(38);
ss.sampleSkewness = require(37);

// measures of centrality
ss.geometricMean = require(13);
ss.harmonicMean = require(14);
ss.mean = ss.average = require(21);
ss.median = require(22);

ss.rootMeanSquare = ss.rms = require(33);
ss.variance = require(49);
ss.tTest = require(47);
ss.tTestTwoSample = require(48);
// ss.jenks = require('./src/jenks');

// Classifiers
ss.bayesian = require(2);
ss.perceptron = require(27);

// Distribution-related methods
ss.epsilon = require(10); // We make ε available to the test suite.
ss.factorial = require(12);
ss.bernoulliDistribution = require(3);
ss.binomialDistribution = require(4);
ss.poissonDistribution = require(28);
ss.chiSquaredGoodnessOfFit = require(6);

// Normal distribution
ss.zScore = require(50);
ss.cumulativeStdNormalProbability = require(9);
ss.standardNormalTable = require(44);
ss.errorFunction = ss.erf = require(11);
ss.inverseErrorFunction = require(16);
ss.probit = require(29);
ss.mixin = require(24);
